<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="font-weight-bolder">แจ้งถอนเงิน</h2>
          <hr />
          <div class="row">
            <div class="col-xl-6">
              <div class="card">
                <div class="card-body shadow rounded">
                  <div class="row">
                    <div class="col-xl-3 text-center">
                      <b-img
                        v-if="bankaccountName !== null"
                        :src="
                          require('@/assets/images/production/bank/' +
                            bankaccountName +
                            '.jpeg')
                        "
                        style="width: 100px"
                        @error="setAltImg"
                      />
                    </div>
                    <div class="col-xl-9 mt-1">
                      <div class="row mb-1">
                        <div class="col-xl-4 d-flex align-self-center">
                          <label style="font-size: 14px">ชื่อบัญชีธนาคาร</label>
                        </div>
                        <div class="col-xl-8 d-flex align-self-center">
                          <b-form-input
                            v-model="fullName"
                            readonly
                          ></b-form-input>
                        </div>
                      </div>
                      <!-- <div class="row mb-1">
                        <div class="col-xl-4 d-flex align-self-center">
                          <label style="font-size: 14px">ธนาคาร</label>
                        </div>
                        <div class="col-xl-8 d-flex align-self-center">
                          <b-form-input disabled></b-form-input>
                        </div>
                      </div> -->
                      <div class="row mb-1">
                        <div class="col-xl-4 d-flex align-self-center">
                          <label style="font-size: 14px">เลขบัญชีธนาคาร</label>
                        </div>
                        <div class="col-xl-8 d-flex align-self-center">
                          <b-form-input
                            v-model="bankaccountNumber"
                            readonly
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end row -->
                </div>
              </div>
              <!-- end card -->
            </div>
          </div>
          <!-- end row bank -->
          <hr />
          <div class="row">
            <div class="col-xl-6">
              <label style="font-size: 15px"
                ><font-awesome-icon
                  :icon="['fas', 'coins']"
                  style="width: 18px"
                />
                จำนวนเงินที่ถอนได้</label
              >
              <br />
              <div class="alert alert-success py-1">
                <h3
                  class="
                    text-success text-center
                    my-0
                    w-100
                    text-credit-balance
                  "
                >
                  {{ creditBalance | toCurrencyBath }}
                </h3>
              </div>
            </div>
            <div class="col-xl-6">
              <label style="font-size: 15px"
                ><font-awesome-icon
                  :icon="['fas', 'hand-holding-usd']"
                  style="width: 18px"
                />
                จำนวนเงินที่ต้องการถอน</label
              >
              <br />
              <b-input-group size="lg">
                <template #prepend>
                  <b-input-group-text style="background-color: #e9ecef"
                    ><span>฿</span></b-input-group-text
                  >
                </template>
                <template #append>
                  <b-button variant="warning" @click="buttonAll"
                    >ทั้งหมด</b-button
                  >
                </template>
                <b-form-input v-model="credit" type="tel"></b-form-input>
              </b-input-group>
              <small class="text-secondary"
                >ถอนเงินขั้นต่ำ 100 หรือ สูงสุด 200,000 บาท</small
              >
            </div>
            <div class="col-xl-12">
              <label style="font-size: 15px" class="mt-1">
                <font-awesome-icon
                  :icon="['far', 'star']"
                  style="width: 18px"
                />หมายเหตุ</label
              >
              <b-form-textarea
                id="textarea"
                v-model="detail"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <!-- end row input -->
          <hr />
          <div class="row">
            <div class="col-xl-6 mb-1">
              <b-button variant="secondary" class="w-100" @click="onBack"
                >ยกเลิก</b-button
              >
            </div>
            <div class="col-xl-6 mb-1">
              <b-button variant="success" class="w-100" @click="onSubmit"
                >ยืนยัน</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import PlaceHolder from "@/assets/images/production/place_images.png";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      credit: 100,
      userId: "",
      detail: "",
      creditBalance: 0,
      bankaccountName: null,
      bankaccountNumber: "",
      fullName: "",
    };
  },
  created() {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // this.userId = userData.id;
    this.loadData();
  },
  methods: {
    setAltImg(e) {
      console.log("alt");
      e.target.src = PlaceHolder;
    },
    onBack() {
      this.$route.push({ path: "/profile" });
    },
    onSubmit() {
      if (this.credit >= 100 && this.credit <= this.creditBalance) {
        const payload = {
          creditAmount: this.credit,
          detail: this.detail,
        };
        this.$store
          .dispatch("transaction/Withdraw", payload)
          .then((result) => {
            if (result.data.success) {
              console.log(result)
              this.$router.replace('/history/withdraw').then(() => {
                const color = result.data.message === 'กรุณารอเจ้าหน้าที่ดำเนินการสักครู่' ? 'warning' : 'success' 
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "ธุรกรรม",
                    icon: "DollarSignIcon",
                    variant: color,
                    text: result.data.message,
                  },
                });
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "ธุรกรรม",
                  icon: "DollarSignIcon",
                  variant: "danger",
                  text: result.data.message,
                },
              });
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "ธุรกรรม",
                icon: "DollarSignIcon",
                variant: "danger",
                text: error.message,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "ธุรกรรม",
            icon: "DollarSignIcon",
            variant: "danger",
            text: "กรุณากรอกจำนวนเงินมากกว่า 100 บาท",
          },
        });
      }
    },
    async loadData() {
      this.$store.commit("game/SET_LOADING", true);
      this.$store
        .dispatch("transaction/getCredit")
        .then((result) => {
          if (result.data.success) {
            this.fullName =
              result.data.item.firstName + " " + result.data.item.lastName;
            this.bankaccountNumber = result.data.item.bankaccountNumber;
            this.bankaccountName = result.data.item.bankaccountName;
            this.$store.commit('auth/SET_CREDIT', result.data.credit)
            this.creditBalance = result.data.credit
            this.$store.commit("game/SET_LOADING", false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buttonAll() {
      console.log("test");
      if (this.creditBalance <= 100) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "ธุรกรรม",
            icon: "DollarSignIcon",
            variant: "danger",
            text: "กรุณากรอกจำนวนเงินมากกว่า 100 บาท",
          },
        });
      } else {
        this.credit = this.creditBalance;
      }
    },
  },
};
</script>

<style scoped>
.text-credit-balance {
  font-weight: bolder;
}
</style>
